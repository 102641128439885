import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import media from 'styles/media'

import downloadIcon from 'assets/icons/download.svg'

import type { DownloadFile } from 'types/downloads'

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0;

  ${media.lg.min} {
    padding: 100px 0;
  }
`

const FileWrapper = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  ${media.lg.min} {
    padding: 20px 0;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    width: 50%;
    flex-direction: row;

    p:first-of-type {
      min-width: 300px;
    }
  }
`

const DownloadButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  p {
    display: none;
  }

  img {
    filter: invert();
  }

  ${media.lg.min} {
    gap: 10px;

    p {
      display: block;
    }
  }
`

type Props = {
  results: DownloadFile[]
}

const Results: React.FC<Props> = ({ results }) => {
  return (
    <Container>
      <Wrapper id="search-results">
        <Text transform="uppercase" weight={700} size={28} margin="30px">
          Wyniki wyszukiwania
        </Text>
        {results?.map((file) => (
          <FileWrapper>
            <InnerWrapper>
              <Text weight={700} margin="0">
                {file.model}
              </Text>
              <Text margin="0">{file.title}</Text>
            </InnerWrapper>
            <DownloadButton as="a" href={file.downloadLink} target="_blank">
              <Icon src={downloadIcon} width={25} height={25} />
              <Text weight={700} transform="uppercase" margin="0" size={14}>
                Pobierz
              </Text>
            </DownloadButton>
          </FileWrapper>
        ))}
      </Wrapper>
    </Container>
  )
}

export default Results
