import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import ButtonWithIcon from 'components/shared/button-with-icon'

import media from 'styles/media'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  padding: 60px 40px;

  ${media.lg.min} {
    padding: 40px;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: 30px;
    max-width: 1000px;
    margin: 0 auto;
  }
`

const Col = styled.div`
  display: flex;
  width: 100%;

  button {
    width: 100%;
  }

  ${media.lg.max} {
    &:not(&:first-of-type) {
      margin-top: 30px;
    }

    button {
      margin-top: 50px;
    }
  }

  .react-select-container {
    width: 100%;

    .react-select {
      &__control {
        height: 100%;
        background-color: ${({ theme }) => theme.colors.black};
        border: none;
        border-radius: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
      }

      &__single-value {
        color: ${({ theme }) => theme.colors.white};
      }

      &__placeholder {
        color: ${({ theme }) => theme.colors.white};
        text-transform: uppercase;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }
`

export type Option = {
  value: string
  label: string
}

type Props = {
  families: Option[] | []
  models: Option[] | []
  years: Option[] | []
  handleCategoryChange: (category: Option) => void
  handleModelChange: (model: Option) => void
  selectedFamily: Option | null
  selectedModel: Option | null
  selectedYear: Option | null
  setSelectedYear: (value: Option) => void
  handleSearch: () => void
}

const Searchbox: React.FC<Props> = ({
  families,
  models,
  years,
  handleCategoryChange,
  handleModelChange,
  selectedFamily,
  selectedModel,
  selectedYear,
  setSelectedYear,
  handleSearch,
}) => {
  return (
    <Wrapper>
      <Col>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          defaultValue={selectedFamily}
          value={selectedFamily}
          onChange={handleCategoryChange}
          options={families}
          placeholder="Rodzina pojazdów"
        />
      </Col>
      <Col>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          defaultValue={selectedModel}
          value={selectedModel}
          onChange={handleModelChange}
          options={models}
          placeholder="Model"
        />
      </Col>
      <Col>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          defaultValue={selectedYear}
          value={selectedYear}
          onChange={setSelectedYear}
          options={years}
          placeholder="Rok"
        />
      </Col>
      <Col>
        <ButtonWithIcon onClick={handleSearch} white icon="zoom">
          Szukaj
        </ButtonWithIcon>
      </Col>
    </Wrapper>
  )
}

export default Searchbox
