import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'

import Searchbox from 'components/pages/downloads/browser/searchbox'
import Results from 'components/pages/downloads/browser/results'

import scrollToSection from 'utils/scrollToSection'

import media from 'styles/media'

import type { DownloadFile } from 'types/downloads'
import type { Option } from 'components/pages/downloads/browser/searchbox'

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const Wrapper = styled.div``

type Props = {
  allFiles: DownloadFile[]
}

const Browser: React.FC<Props> = ({ allFiles }) => {
  const [selectedFamily, setSelectedFamily] = useState<Option | null>(null)
  const [selectedModel, setSelectedModel] = useState<Option | null>(null)
  const [selectedYear, setSelectedYear] = useState<Option | null>(null)

  const [families, setFamilies] = useState(
    Array.from(new Set(allFiles?.map((file) => file.category))).map(
      (category) => ({
        value: category,
        label: category,
      })
    )
  )
  const [models, setModels] = useState<Option[] | []>([])
  const [years, setYears] = useState<Option[] | []>([])

  const handleCategoryChange = (category: Option) => {
    setSelectedFamily(category)
    setSelectedModel(null)
    setSelectedYear(null)
  }

  const handleModelChange = (model: Option) => {
    setSelectedModel(model)
    setSelectedYear(null)
  }

  useEffect(() => {
    setModels(
      Array.from(
        new Set(
          allFiles
            .filter((file) => {
              if (selectedFamily) {
                return file.category === selectedFamily?.value
              }
              return true
            })
            .map((file) => file.model)
        )
      ).map((model) => ({
        value: model,
        label: model,
      }))
    )

    setYears(
      Array.from(
        new Set(
          allFiles
            .filter((file) => {
              if (selectedFamily) {
                return file.category === selectedFamily?.value
              }
              return true
            })
            .filter((file) => {
              if (selectedModel) {
                return file.model === selectedModel?.value
              }
              return true
            })
            .map((file) => file.year)
        )
      ).map((year) => ({
        value: year,
        label: year,
      }))
    )
  }, [selectedFamily, selectedModel])

  const [results, setResults] = useState(allFiles)

  const handleSearch = () => {
    setResults(
      allFiles?.filter((file) => {
        if (selectedFamily) {
          if (file.category !== selectedFamily.value) {
            return false
          }
        }
        if (selectedModel) {
          if (file.model !== selectedModel.value) {
            return false
          }
        }
        if (selectedYear) {
          if (file.year !== selectedYear.value) {
            return false
          }
        }
        return true
      })
    )
    scrollToSection('#search-results')
  }

  return (
    <StyledContainer>
      <Wrapper>
        <Searchbox
          families={families}
          models={models}
          years={years}
          handleCategoryChange={handleCategoryChange}
          handleModelChange={handleModelChange}
          selectedFamily={selectedFamily}
          selectedModel={selectedModel}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          handleSearch={handleSearch}
        />
        <Results results={results} />
      </Wrapper>
    </StyledContainer>
  )
}

export default Browser
