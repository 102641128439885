import React from 'react'
import styled from 'styled-components'

import { Heading, Text } from 'components/shared/typography'
import Container from 'components/shared/container'

import media from 'styles/media'

const Wrapper = styled.div`
  padding: 25px 0;

  ${media.lg.min} {
    padding: 50px 0 25px 0;

    h1,
    p {
      text-align: center;
    }
  }
`

type Props = {
  heading: string
  text: string
}

const Intro: React.FC<Props> = ({ heading, text }) => {
  return (
    <Container>
      <Wrapper>
        <Heading
          transform="uppercase"
          size={44}
          margin="15px"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Text dangerouslySetInnerHTML={{ __html: text }} />
      </Wrapper>
    </Container>
  )
}

export default Intro
