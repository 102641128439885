// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import Hero from 'components/pages/downloads/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'
import Intro from 'components/pages/downloads/intro'
import Browser from 'components/pages/downloads/browser'

// types
import type { DownloadFile, Category } from 'types/downloads'

const DoPobrania: React.FC<PageProps<Queries.DoPobraniaPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.downloads
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const DOWNLOAD_FILES: DownloadFile[] = PAGE?.downloadsFiles?.map(
    (fileItem) => ({
      category: fileItem?.downloadsFilesFamily! as Category,
      model: fileItem?.downloadsFilesModel!,
      year: fileItem?.downloadsFilesYears!,
      title: fileItem?.downloadsFilesLabel!,
      downloadLink: fileItem?.downloadsFilesSingle?.link!,
    })
  )!

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero
        heading={PAGE?.downloadsBannerTitle!}
        image={{
          src: PAGE?.downloadsImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.downloadsImg?.altText!,
        }}
      />
      <Breadcrumbs crumbs={[{ name: data?.wpPage?.title! }]} />
      <Intro
        heading={PAGE?.downloadsTitle!}
        text={PAGE?.downloadsDescription!}
      />
      <Browser allFiles={DOWNLOAD_FILES} />
    </Layout>
  )
}

export default DoPobrania

export const query = graphql`
  query DoPobraniaPage {
    wpPage(slug: { regex: "/pobierz/" }) {
      title
      downloads {
        downloadsBannerTitle
        downloadsTitle
        downloadsDescription
        downloadsFiles {
          downloadsFilesSingle {
            link
          }
          downloadsFilesLabel
          downloadsFilesYears
          downloadsFilesModel
          downloadsFilesFamily
        }
        downloadsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                breakpoints: [1280, 1920]
              )
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
  }
`
